import { createApp } from 'vue';
import { createPinia } from 'pinia';

import '@/assets/main.less';
import 'tdesign-mobile-vue/es/style/index.css';

import App from './App.vue';
import router from './router';

const app = createApp(App);

app.use(createPinia());
app.use(router);

app.mount('#app');
