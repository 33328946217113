<script setup lang="ts">
import { RouterView } from 'vue-router';
console.log(import.meta.env.DEV);
</script>

<template>
  <router-view />
</template>

<style scoped></style>
